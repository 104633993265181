globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"storefront"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/nextjs";

import { COMMON_CLIENT_CONFIG } from "@projectluna/lib/config/common/client";

import { sentryCommonConfig } from "./sentry.common.config";

Sentry.init({
  ...sentryCommonConfig,
  replaysSessionSampleRate: COMMON_CLIENT_CONFIG.IS_DEVELOPMENT ? 0.1 : 1.0,
  replaysOnErrorSampleRate: 1.0,
});
