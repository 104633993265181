import(/* webpackMode: "eager" */ "/vercel/path0/apps/storefront/src/assets/svg/facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/storefront/src/assets/svg/instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/storefront/src/assets/svg/logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/storefront/src/assets/svg/pinterest.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/storefront/src/assets/svg/tiktok.svg");
;
import(/* webpackMode: "eager", webpackExports: ["Logo"] */ "/vercel/path0/apps/storefront/src/components/Header/components/Logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileMenu"] */ "/vercel/path0/apps/storefront/src/components/Header/components/MobileMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PromotionsBar"] */ "/vercel/path0/apps/storefront/src/components/Header/components/PromotionsBar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/storefront/src/components/Header/components/Search.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/storefront/src/components/Header/components/ShoppingBagIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Wrapper"] */ "/vercel/path0/apps/storefront/src/components/Header/components/Wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Cart"] */ "/vercel/path0/apps/storefront/src/components/SideCart/components/Cart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartDrawer"] */ "/vercel/path0/apps/storefront/src/components/SideCart/components/CartDrawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EmptyCart"] */ "/vercel/path0/apps/storefront/src/components/SideCart/components/EmptyCart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.24.0_next@15.1.1-canary.23_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@_sdit7ewkqsnwko5xegq3sx2kve/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/next@15.1.1-canary.23_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
