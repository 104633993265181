import { captureConsoleIntegration, type init } from "@sentry/nextjs";

import { COMMON_CLIENT_CONFIG } from "@projectluna/lib/config/common/client";

import { CLIENT_CONFIG } from "./src/config/client";

type InitOptions = Parameters<typeof init>[0];

export const sentryCommonConfig: InitOptions = {
  debug: process.env.NEXT_PUBLIC_SENTRY_DEBUG === "true",
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: !!process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: COMMON_CLIENT_CONFIG.ENVIRONMENT,

  integrations: [captureConsoleIntegration({ levels: ["error"] })],
  release: CLIENT_CONFIG.RELEASE,
  tracesSampleRate: COMMON_CLIENT_CONFIG.IS_DEVELOPMENT ? 0.25 : 1.0,
  sampleRate: COMMON_CLIENT_CONFIG.IS_DEVELOPMENT ? 0.25 : 1.0,

  normalizeDepth: 10,

  ignoreErrors: [
    "Suspense Exception: This is not a real erro",
    "needs to bail out of prerendering at this point",
    "Expected a suspended thenable",
    "SyntaxError: Unexpected token '<'", // TODO Investigate
    "/var/task/___vc/__launcher/__launcher.js",
    "Minified React error #419",
    "Failed to Fetch errors occur when there is an error with Fetch API", // TODO: https://sentry.io/answers/failed-to-fetch-javascript/
    "globalThis is not defined",
    /**
     * React errors without any helpful stacktrace.
     */
    "Minified React error #422", // TODO: https://react.dev/errors/422
    "Minified React error #423",
    "Minified React error #418",
    "Minified React error #425",
    "Minified React error #329",
    /**
     * Predefined https://docs.sentry.io/platforms/javascript/configuration/filtering/#using--1
     */
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
  ],

  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
};
