"use client";

import { DEFAULT_MARKET_ID } from "@projectluna/lib/market/types";
import { getPaths } from "@projectluna/lib/paths/getPaths";

import { useGetMarket } from "../market/client";

export const useLocalizedPaths = () => {
  const market = useGetMarket();

  if (DEFAULT_MARKET_ID === market.id) {
    return getPaths("");
  }

  return getPaths(market.slug);
};
