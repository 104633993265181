import(/* webpackMode: "eager" */ "/vercel/path0/apps/storefront/src/assets/images/home_hero.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/storefront/src/assets/svg/logo_sign.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/storefront/src/components/NextImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/vercel/path0/apps/storefront/src/components/ProductCard/components/Image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Name"] */ "/vercel/path0/apps/storefront/src/components/ProductCard/components/Name.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Price"] */ "/vercel/path0/apps/storefront/src/components/ProductCard/components/Price.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/vercel/path0/apps/storefront/src/components/ProductCarousel/components/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/storefront/src/components/VariantCard/VariantCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.24.0_next@15.1.1-canary.23_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@_sdit7ewkqsnwko5xegq3sx2kve/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.1-canary.23_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
