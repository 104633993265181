import(/* webpackMode: "eager", webpackExports: ["Version"] */ "/vercel/path0/apps/storefront/src/app/[marketId]/components/Version.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppRouterProgressBar"] */ "/vercel/path0/apps/storefront/src/components/AppRouterProgressBar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/storefront/src/components/CookieConsent/CookieConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MarketProvider"] */ "/vercel/path0/apps/storefront/src/lib/market/client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IntlProvider"] */ "/vercel/path0/apps/storefront/src/providers/IntlProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SideCartProvider"] */ "/vercel/path0/apps/storefront/src/providers/SideCartProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/storefront/src/providers/SnackbarClientProvider/SnackbarClientProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@15.0.2_next@15.1.1-canary.23_@babel+core@7.25.9_@opentelemetry+api@1.9.0__ya5dtqdf5ohgq3mfdj2ibqmvcu/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@15.0.2_next@15.1.1-canary.23_@babel+core@7.25.9_@opentelemetry+api@1.9.0__ya5dtqdf5ohgq3mfdj2ibqmvcu/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@15.0.2_next@15.1.1-canary.23_@babel+core@7.25.9_@opentelemetry+api@1.9.0__ya5dtqdf5ohgq3mfdj2ibqmvcu/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@15.1.1-canary.23_@babel+core@7.25.9_@opentelemetry+api@1.9.0_rea_hmaby6gismwd4vz3cwwoaocevq/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.13_next@15.1.1-canary.23_@babel+core@7.25.9_@opentelemetry+api@1.9_cf24npwxvo455wl6pk2pxxpk7q/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.24.0_next@15.1.1-canary.23_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@_sdit7ewkqsnwko5xegq3sx2kve/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.1-canary.23_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.1-canary.23_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Red_Hat_Display\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin-ext\"],\"variable\":\"--font-primary\",\"display\":\"block\"}],\"variableName\":\"primaryFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.1-canary.23_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../assets/fonts/Aboreto.ttf\",\"weight\":\"400\",\"variable\":\"--font-secondary\",\"display\":\"block\"}],\"variableName\":\"secondaryFont\"}");
